@font-face {
  font-family: Sabon;
  src: url('../fonts/SabonLTStd-Bold.otf');
}

@font-face {
    font-family: IBMPlex;
    src: url('../fonts/IBMPlexMono-Regular.ttf');
}

@font-face {
    font-family: BebasNeue;
    src: url('../fonts/BebasNeue-Regular.ttf');
}

html,body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

.header-item {
  font-family: Sabon;
}

.Header {
  font-family: 'Raleway', sans-serif;
  font-weight: 200;
  font-size: 22px;
  color: #f2f2f2;
}

.main-title {
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
}

.main-paragraph {
  font-size: 38px !important;

  @media screen and (max-width: 960px){
    font-size: 60px !important;
    font-weight: 400 !important;
    padding-bottom: 20px;
  }
}

.paragraph {
  font-family: 'Raleway', sans-serif;
  color: #f2f2f2;
  font-size: 26px;
  font-weight: 300;
  margin: 10px;

  @media screen and (max-width: 960px){
    font-size: 30px ;
    font-weight: 400 !important;
  }
}

.social {
  width: 40px;

  @media screen and (max-width: 960px){
    width: 60px;
  }
}

.AboutText {
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
}

.List {
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 40px;
  font-size: 22px;
  font-family: IBMPlex;
  color: #9c9c9c;

  @media screen and (max-width: 960px){
    font-size: 2em;
    line-height: 50px;
  }
}